<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description[0]" />
      </v-col>
      <v-col cols="12" md="8" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5" md="3"
          >
            <span class="font-normal nameField--text">Recovery codes</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="7" md="9">
            <ToggleButton
              v-model="show"
              :buttons="options"
            />
          </v-col>
        </v-row>

        <v-slide-y-transition hide-on-leave>
          <v-row no-gutters v-if="show">
            <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
              <v-card
                class="create-card leftDrawerHoverMenuBg recovery-code-container pa-12"
                :loading="loading"
                loader-height="2"
                elevation="0"
              >
                <div
                  class="font-normal"
                  :class="{ 'pt-6': index }"
                  v-for="(code, index) in codes"
                  :key="index"
                >{{ code }}</div>
              </v-card>
            </v-col>

            <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
              <ActionButton type="submit" @click.prevent="regenerateCodes" :loading="loadingAction">
                Regenerate codes
              </ActionButton>
            </v-col>
          </v-row>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionButton from '@/components/buttons/ActionButton'
import InputPassword from '@/components/inputs/InputPassword'

import {getRecoveryCodes, generateRecoveryCodes } from '@/api/auth-routes'

export default {
  name: 'CardRecoveryCodes',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, ActionButton, InputPassword },
  data: () => ({
    loading: false,
    loadingAction: false,
    description: [
      {
        title: 'Save your recovery codes',
        text: [
          'If you lose access to your phone,',
          'you won\'t be able to log',
          'in without a recovery code.',
          'Print, copy or write down your codes',
          'and save it somewhere safe.'
        ]
      },
    ],
    show: 0,
    options: [
      { name: 'Hide', id: 0 },
      { name: 'Show', id: 1 },
    ],
    codes: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    async loadRecoveryCodes() {
      this.loading = true

      const { success, payload, message } = await getRecoveryCodes()

      if (success) {
        this.codes = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async regenerateCodes() {
      this.loadingAction = true

      const { success, payload, message } = await generateRecoveryCodes()

      if (success) {
        this.codes = payload
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
  },
  watch: {
    show: {
      handler(show) {
        if (!!show) {
          this.loadRecoveryCodes()
        } else {
          this.codes = []
        }
      }
    }
  }
}
</script>