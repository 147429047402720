<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description[0]" />
      </v-col>
      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Enable 2fa</span>
            </v-col>

            <v-col class="pb-10" cols="12" sm="7" md="9">
              <SwitchLg
                v-model="enabled"
                bgColor="switcherViolet"
              />
            </v-col>
          </v-row>

          <v-slide-y-transition hide-on-leave>
            <v-row no-gutters v-if="enabled && !getTwoFactorAuthId">
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Methods</span>
              </v-col>

              <v-col class="pb-10" cols="12" sm="7" md="9">
                <SelectField
                  v-model="currentMethod"
                  class="field-container"
                  :items="authMethods"
                  :height="46"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                  :menu-props="{ 'offset-y': true, 'nudge-top': -4}"
                  checkBg
                />
              </v-col>
            </v-row>
          </v-slide-y-transition>

          <v-slide-y-transition hide-on-leave>
            <v-row no-gutters v-if="qrCode">
              <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
                <div class="font-normal descriptionTitle--text">1. Scan this QR code with your app</div>
                <div class="font-normal nameField--text pt-2">Scan the image below with the two-factor authentication app on your phone</div>
              </v-col>

              <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
                <v-img max-width="250" :src="qrCode"></v-img>
              </v-col>

              <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
                <div class="font-normal descriptionTitle--text">2. Enter the six-digit code from the application</div>
                <div class="font-normal nameField--text pt-2">After scanning the QR code, the app will display a six-digit code that you can enter below to validate it and then click on save</div>
              </v-col>

              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="4" lg="3"
              >
                <span class="font-normal nameField--text">Validation code</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="validationCode"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                <FormField
                  v-model="code"
                  class="field-container"
                  :height="46"
                  :rules="rules"
                  placeholder="123456"
                  checkBg
                >
                  <template #message="{ key, message }">
                    <portal to="validationCode" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            </v-row>
          </v-slide-y-transition>

          <v-slide-y-transition>
            <v-row no-gutters v-if="!enabled && !!getTwoFactorAuthId">
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="4" lg="3"
              >
                <span class="font-normal nameField--text">Validation code</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="disableValidationCode"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                <FormField
                  v-model="disableCode"
                  class="field-container"
                  :height="46"
                  :rules="rules"
                  placeholder="123456"
                  checkBg
                >
                  <template #message="{ key, message }">
                    <portal to="disableValidationCode" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            </v-row>
          </v-slide-y-transition>


          <v-row no-gutters v-if="!enabled && !!getTwoFactorAuthId || qrCode">
            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
              <CancelButton @click="closeAction" class="mr-8 mr-sm-10">Cancel</CancelButton>

              <ActionButton type="submit" @click.prevent="submitForm" :loading="loadingAction">
                {{ enabled ? 'Enable' : 'Disable' }}
              </ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import SelectField from '@/components/select/SelectField'
import SwitchLg from '@/components/controls/SwitchLg'

import { mapGetters, mapMutations } from 'vuex'

import { getAuthMethods, sendEnableAuthMethod, sendEnableVerificationCode, sendDisableVerificationCode } from '@/api/auth-routes'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'CardTwoFactorAuth',
  inheritAttrs: false,
  components: { ActionButton, CancelButton, TextDescription, FormField, SelectField, SwitchLg },
  data: () => ({
    validateForm: true,
    loading: false,
    enabled: false,
    authMethods: [],
    currentMethod: null,
    code: null,
    disableCode: null,

    rules: [],
    description: [
      {
        title: 'Two-Factor Authentication',
        text: []
      },
    ],
    editUrlParamsId: null,
    loadingDetailUrlParams: false,
    loadingAction: false,
    qrCode: null,
  }),
  computed: {
    ...mapGetters({
      getTwoFactorAuthId: authTypes.getters.GET_TWO_FACTOR_AUTH_ID,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    this.loadAuthMethods()
  },
  methods: {
    ...mapMutations({
      setTwoFactorAuthId: authTypes.mutations.SET_TWO_FACTOR_AUTH_ID,
    }),
    async loadAuthMethods() {
      this.loading = true

      const { success, payload, message } = await getAuthMethods()

      if (success) {
        this.authMethods = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    closeAction() {
      this.$router.push({ name: 'Dashboard' })
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true

        if (this.enabled) {
          this.enable2fa()
        } else {
          this.disable2fs()
        }

        this.loading = false
      }
    },
    async enable2fa() {
      const { success, payload, message } = await sendEnableVerificationCode({
        methodId: this.currentMethod,
        formData: {
          input: this.code
        }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.setTwoFactorAuthId(this.currentMethod)
        this.qrCode = null
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async disable2fs() {
      const { success, payload, message } = await sendDisableVerificationCode({
        methodId: this.getTwoFactorAuthId,
        params: {
          input: this.disableCode
        }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.setTwoFactorAuthId(null)
        this.disableCode = null
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    async sendEnableMethod() {
      this.loading = true

      const { success, payload, message } = await sendEnableAuthMethod(this.currentMethod)

      if (success) {
        this.qrCode = payload['qr_code_svg']
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
  },
  watch: {
    getTwoFactorAuthId: {
      immediate: true,
      handler(id) {
        this.enabled = !!id
      }
    },
    enabled: {
      handler(enabled) {
        if (!enabled) {
          this.currentMethod = null
          this.qrCode = null
          this.code = null
          this.disableCode = null
          this.rules = []
        }
      }
    },
    currentMethod: {
      handler(id) {
        if (id) {
          this.sendEnableMethod()
        }
      }
    },
  },
}
</script>