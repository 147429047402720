<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">Two-factor Auth</div>
        </portal>
      </div>

      <div>
        <CancelButton :to="{ name: 'Dashboard' }">Close</CancelButton>
      </div>
    </div>

    <CardTwoFactorAuth

    />

    <CardRecoveryCodes
      v-if="getTwoFactorAuthId"
      class="mt-15"
    />
  </v-container>
</template>

<script>
import CancelButton from '@/components/buttons/CancelButton'
import CardTwoFactorAuth from '@/components/cards/CardTwoFactorAuth'
import CardRecoveryCodes from '@/components/cards/CardRecoveryCodes'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'TwoStepAuth',
  components: { CancelButton, CardTwoFactorAuth, CardRecoveryCodes },
  data: () => ({

  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    ...mapGetters({
      getTwoFactorAuthId: authTypes.getters.GET_TWO_FACTOR_AUTH_ID,
    }),
  },
  mounted() {

  },
  methods: {

  },
}
</script>